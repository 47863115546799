import Editor, { loader } from '@monaco-editor/react';
import { debounce } from 'lodash-es';
import markdownPlugin from 'prettier/plugins/markdown';
import { useCallback, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { usePasteKeyboardListener } from '../../../modules/note/pc/hooks/usePasteKeyboardListener';
import { REQUEST_BASE_URL } from '../../../utils/constant';
import { uploadFile } from '../../../utils/qiniu';
import { useNoteContext } from './NoteContext';
import {
	shortCutSave,
	useKeyDownKeyboardListener,
} from './hooks/useKeyDownKeyboardListener';
import { useNoteCommon } from './hooks/useNoteCommon';

import style from './style.module.css';

import * as monaco from 'monaco-editor';

import { Button } from 'antd';
import type { Note } from 'blog/type-file';
// import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
// import cssWorker from 'monaco-editor/esm/vs/language/css/css.worker?worker';
// import htmlWorker from 'monaco-editor/esm/vs/language/html/html.worker?worker';
// import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
// import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import { format } from 'prettier';
import { useMount } from 'ahooks';
export const notChecked = 0;

// self.MonacoEnvironment = {
// 	getWorker(_, label) {
// 		if (label === 'json') {
// 			return new jsonWorker();
// 		}
// 		if (label === 'css' || label === 'scss' || label === 'less') {
// 			return new cssWorker();
// 		}
// 		if (label === 'html' || label === 'handlebars' || label === 'razor') {
// 			return new htmlWorker();
// 		}
// 		if (label === 'typescript' || label === 'javascript') {
// 			return new tsWorker();
// 		}
// 		return new editorWorker();
// 	},
// };

// define a document formatting provider
// then you contextmenu will add an "Format Document" action
// monaco.languages.registerDocumentFormattingEditProvider('markdown', {
// 	async provideDocumentFormattingEdits(model, options) {
// 		const formattedContent = await format(model.getValue() ?? '', {
// 			parser: 'markdown',
// 			plugins: [markdownPlugin],
// 		});

// 		console.log('markdown format');
// 		return [
// 			{
// 				range: model.getFullModelRange(),
// 				text: formattedContent,
// 			},
// 		];
// 	},
// });

// loader.config({ paths: { vs: '/monaco-editor' } });
interface Props {
	useEditor: boolean;
	onSave: (note: Note) => void;
}
const Write = ({ useEditor, onSave }: Props) => {
	const { noteDetail } = useNoteContext();
	const { doInitStatus, onSubmit, writeForm, isLoading } = useNoteCommon({
		onSave: onSave,
	});
	const { control, register, handleSubmit, getValues, setValue } = writeForm;
	useKeyDownKeyboardListener((e) => shortCutSave(e, handleSubmit(onSubmit)));
	const editRef = useRef();

	const uploadedCallback = useCallback(
		(res: any) => {
			const link = `${REQUEST_BASE_URL.replace('https://api', 'http://file')}/${
				res.key
			}`;

			const content =
				res.type.indexOf('image') >= 0
					? `\n![${res.name}](${link})`
					: `\n[${res.name}](${link})`;

			const prev = getValues('content');
			setValue('content', prev + content);

			return res;
		},
		[getValues, setValue],
	);

	const onPasteUpload = useCallback(
		async (e: any) => {
			const items = e.clipboardData.items;
			for (const item of items) {
				if (item.type === 'image/png') {
					const file = new File(
						[item.getAsFile()],
						`${new Date().getTime()}.png`,
					);

					const res = await uploadFile(file);
					uploadedCallback(res);
				}
			}
		},
		[uploadedCallback],
	);

	usePasteKeyboardListener(onPasteUpload);

	useEffect(() => {
		if (noteDetail.data) {
			doInitStatus();
		}
	}, [noteDetail.data]);

	useMount(() => {
		console.log('mount', editRef.current);
		if (!editRef.current) {
			return;
		}

		const myEditor = monaco.editor.create(editRef.current, {
			value: '',
			language: 'javascript',
			automaticLayout: true,
		});
	});

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col">
					{/* <div className="flex flex-col w-4/5 "></div> */}

					{/* <input
            id="title"
            disabled
            defaultValue={entity.title}
            className={`my-1 w-full border-gray-200 border `}
            {...register('title', { required: true })}
          ></input> */}

					{/* <Controller
              control={control}
              name='type'
              render={({ field }) => <TagsSelect {...field} hasEditMode={false} />}
            />   */}
					<div className="justify-center flex">
						{useEditor ? (
							<Controller
								control={control}
								name="content"
								render={({
									field: { onChange: onChangeValue, onBlur, value, ref },
								}) => {
									return (
										<Editor
											height="calc(100vh - 100px)"
											options={{
												wordWrap: 'bounded',

												unicodeHighlight: {
													ambiguousCharacters: false,
												},
											}}
											defaultLanguage="markdown"
											value={value}
											onChange={debounce((beChangedValue?: string) => {
												onChangeValue(beChangedValue);
											}, 20)}
											onMount={(editor, monaco) => {
												ref(editor);
											}}
										/>
									);
								}}
							/>
						) : (
							<textarea
								className={style['native-text']}
								rows={8}
								{...register('content', { required: true })}
							/>
						)}
					</div>
					<Button className="m-3 " htmlType="submit">
						Save
					</Button>
				</div>
			</form>

			{isLoading && <div>SAVING</div>}
		</>
	);
};

export default Write;
