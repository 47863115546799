import { useMount } from 'ahooks';
import { useRef, useState } from 'react';

export const nameScrollableDiv = 'scrollableDiv';

export function useContainerAdapt(maxHeight?: number) {
	const [screenHeight, setScreenHeight] = useState(800);
	const noteListContainerRef = useRef<HTMLDivElement | null>();
	useMount(() => {
		const bodyHeight = document.body.offsetHeight - 2;
		const children =
			(noteListContainerRef.current
				?.children as HTMLCollectionOf<HTMLElement>) ?? [];

		const childrenHeight = Array.from(children).reduce((acc, cur) => {
			const offsetHeight = cur.id !== nameScrollableDiv ? cur?.offsetHeight : 0;
			return acc + offsetHeight;
		}, 0);

		const height = bodyHeight - childrenHeight;

		if (!maxHeight) {
			maxHeight = window.innerHeight;
		}

		setScreenHeight(height > maxHeight ? maxHeight : height);
	});

	return {
		screenHeight,
		noteListContainerRef,
	};
}
