import { collSideBar } from '../../../modules/note/pc/components/collSideBar';
import { initEntity, modesType } from '../../../modules/note/pc/consts';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { noteIdQueryConstantName, useNoteContext } from './NoteContext';

const Menu = ({
	sideBarHook,
	formHook,
}: {
	sideBarHook: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
	formHook: any;
}) => {
	const { writeForm } = useNoteContext();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const action = (searchParams.get('action') as modesType) ?? modesType.preview;
	const id = searchParams.get(noteIdQueryConstantName);
	const modulePath = 'pc';
	return (
		<>
			{collSideBar(sideBarHook[1], sideBarHook[0])}

			{/* <div
				className="text-blue-200 cursor-pointer"
				onMouseUp={() => {
					navigate(`/${modulePath}/calendar`);
				}}
			>
				calendar
			</div> */}

			<div
				className="text-blue-200 cursor-pointer"
				onMouseUp={() => {
					setSearchParams((p) => {
						p.set('action', modesType.create);
						p.set('tagId', formHook.getValues('tagIds') ?? '');
						p.set('uuid', '');
						return p;
					});

					writeForm.reset({ ...initEntity });
				}}
			>
				创建
			</div>

			{id && modesType.preview !== action && (
				<div
					className="text-blue-200 cursor-pointer"
					onMouseUp={() => {
						setSearchParams((p) => {
							p.set('action', modesType.preview);
							p.set(noteIdQueryConstantName, id);
							return p;
						});
					}}
				>
					预览
				</div>
			)}

			{id && modesType.editAndPreview !== action && (
				<div
					className="text-blue-200 cursor-pointer"
					onMouseUp={() => {
						setSearchParams((p) => {
							p.set('action', modesType.editAndPreview);
							p.set(noteIdQueryConstantName, id);
							return p;
						});
					}}
				>
					编辑
				</div>
			)}
		</>
	);
};

export default Menu;
