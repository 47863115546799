export function collSideBar(
	setIsShowSideBar: (value: boolean) => void,
	isShowSideBar: boolean,
) {
	return (
		<div
			className="p-3 text-lg text-nowrap whitespace-nowrap"
			onMouseDown={() => {
				setIsShowSideBar(!isShowSideBar);
			}}
			// onTouchEnd={() => {
			// 	setIsShowSideBar(!isShowSideBar);
			// }}
		>
			列表
		</div>
	);
}
