import { modesType } from '../../../modules/note/pc/consts';
import Detail from '../../../modules/note/pc/Detail';
import ModeExec from '../../../modules/note/pc/ModeExec';
import { useNoteContext } from '../../../modules/note/pc/NoteContext';
import Write from '../../../modules/note/pc/Write';
import { useUserAgent } from '../../../utils/userAgent';

import style from './style.module.css';
import { WriteModeMenu } from './WriteModeMenu';

const NoteModule = () => {
	const context = useNoteContext();
	const { isMobile, isPC } = useUserAgent();

	if (modesType.preview === context.action) {
		return (
			<ModeExec>
				<div className="col-span-4 overflow-auto h-screen">
					<Detail />
				</div>
			</ModeExec>
		);
	}

	return (
		<ModeExec>
			<div className="md:grid md:grid-cols-4 sm:flex sm:flex-col overflow-y-scroll">
				{(modesType.editAndPreview === context.action ||
					modesType.create === context.action) && (
					<div className={'col-span-3 flex flex-col flex-initial'}>
						<Write
							useEditor={isPC}
							onSave={() => {
								context.listHook.refetch();
							}}
						/>
					</div>
				)}

				{context.action === modesType.preview && (
					<div className="overflow-auto mx-1 h-screen col-span-3">
						<Detail />
					</div>
				)}

				<div className="flex flex-col space-y-2 p-3">
					<WriteModeMenu />
				</div>
			</div>
		</ModeExec>
	);
};

export { NoteModule };
