import { KEYS_CONST_NAME } from '../../../modules/note/pc/consts';
import { MarkdownRender } from './MarkdownRender';
import { useNoteContext } from './NoteContext';
const Detail = () => {
	const context = useNoteContext();
	const keys = context?.formHook?.getValues(KEYS_CONST_NAME);
	const data = context?.noteDetail?.data;

	if (data) {
		return (
			<div className="p-5">
				<MarkdownRender
					key={data.id?.toString() ?? '-1'}
					detail={data}
					lightWord={keys?.trim()?.split(' ') ?? []}
				/>
			</div>
		);
	}

	return null;
};

export default Detail;
